var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: {
        "hide-title": _vm.hideTitle,
        title: "Household Monthly Income",
        "hide-actions": ""
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("v-simple-table", {
        staticClass: "mt-2",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", { staticClass: "my-1" }, [
                    _c(
                      "th",
                      { staticClass: "text-left", attrs: { width: "160" } },
                      [_vm._v("Applicant")]
                    ),
                    _c(
                      "th",
                      { staticClass: "text-left", attrs: { width: "160" } },
                      [_vm._v("Type")]
                    ),
                    _c("th", { staticClass: "text-left" }, [_vm._v("Source")]),
                    _c("th", { staticClass: "text-right" }, [_vm._v("Amount")]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("Verified")
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.items, function(item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.applicant))]),
                      _c("td", [_vm._v(_vm._s(item.type))]),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(item.description))
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$dollarFormat(item.monthly_income)) +
                            " "
                        )
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          item.verified
                            ? _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-check-circle")
                              ])
                            : _c(
                                "v-icon",
                                { staticClass: "grey--text text--lighten-2" },
                                [_vm._v("mdi-check-circle")]
                              )
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }