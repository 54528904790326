<template>
    <psi-dialog
        :hide-title="hideTitle"
        title="Household Monthly Income"
        @close="$emit('close')"
        hide-actions
    >
        <v-simple-table class="mt-2">
            <template v-slot:default>
                <thead>
                    <tr class="my-1">
                        <th class="text-left" width="160">Applicant</th>
                        <th class="text-left" width="160">Type</th>
                        <th class="text-left">Source</th>
                        <th class="text-right">Amount</th>
                        <th class="text-center">Verified</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td>{{ item.applicant }}</td>
                        <td>{{ item.type }}</td>
                        <td class="text-left">{{ item.description }}</td>
                        <td class="text-right">
                            {{ $dollarFormat(item.monthly_income) }}
                        </td>
                        <td class="text-center">
                            <v-icon v-if="item.verified" color="primary"
                                >mdi-check-circle</v-icon
                            ><v-icon v-else class="grey--text text--lighten-2"
                                >mdi-check-circle</v-icon
                            >
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </psi-dialog>
</template>
<script>
export default {
    name: "screening-household-summary-income",
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
        hideTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
};
</script>

<style scoped>
</style>