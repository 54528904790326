import { render, staticRenderFns } from "./ScreeningHouseholdSummaryIncome.vue?vue&type=template&id=6deb6570&scoped=true&"
import script from "./ScreeningHouseholdSummaryIncome.vue?vue&type=script&lang=js&"
export * from "./ScreeningHouseholdSummaryIncome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6deb6570",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VIcon,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6deb6570')) {
      api.createRecord('6deb6570', component.options)
    } else {
      api.reload('6deb6570', component.options)
    }
    module.hot.accept("./ScreeningHouseholdSummaryIncome.vue?vue&type=template&id=6deb6570&scoped=true&", function () {
      api.rerender('6deb6570', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/screen/components/ScreeningHouseholdSummaryIncome.vue"
export default component.exports